import Error from 'next/error'
import Layout from '../components/layout'
import PageData from '../types/PageData'
import { StrapiGlobal } from '../types/StrapiGlobal'

interface NotFoundPageProps {
  global: StrapiGlobal
  pageData: PageData
  preview: boolean
}

const NotFoundPage = ({ global, pageData, preview }: NotFoundPageProps) => (
  <Layout
    global={global}
    seo={pageData?.attributes?.seo}
    locale={pageData?.attributes?.locale}
    preview={preview}
  >
    <Error statusCode={404} withDarkMode={false} />
  </Layout>
)

export default NotFoundPage
